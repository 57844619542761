export const BG_COUNTING_DOWN = `https://ik.imagekit.io/farahoktarina/AtinaDimas/3.%20Counting%20Days.JPG?updatedAt=1706087398038`;
export const BG_PHOTO_CONTENT = `https://ik.imagekit.io/farahoktarina/AtinaDimas/5.%20Cover%20Gallery%20Luar.jpg?updatedAt=1703763017571`;
export const BG_IG_FILTER = `https://ik.imagekit.io/farahoktarina/AtinaDimas/Cover%20Atinan&%20Dimas.jpg?updatedAt=1705309204767`;
export const BG_LOVE_STORY = BG_PHOTO_CONTENT;
export const BG_DESKTOP_VIEW = `https://ik.imagekit.io/farahoktarina/AtinaDimas/4.jpg?updatedAt=1704220276446`;

// couple image
export const IMG_LOVE_STORY = ``;
export const IMG_MAN = `https://ik.imagekit.io/farahoktarina/AtinaDimas/2.%20Groom.jpg?updatedAt=1703762361310`;
export const IMG_GIRL = `https://ik.imagekit.io/farahoktarina/AtinaDimas/1.%20Bride.jpg?updatedAt=1703762361620`;
export const IMG_PHOTO_GALLERY = `https://ik.imagekit.io/farahoktarina/AtinaDimas/Copy%20of%204.jpg?updatedAt=1702276218277`;

// image assets
import logo from '@/assets/img/logo.png'
export const IMG_LOGO_INITIAL = logo;
export const IMG_LOGO = `https://user-images.githubusercontent.com/10141928/155884146-da96e848-c0c1-4477-8ddf-edbf62813e9f.png`;
export const IMG_LOGO_FOOTER = `https://user-images.githubusercontent.com/10141928/155889521-d2d24571-a270-4a10-b1a8-2e3e5c0d7ecc.png`;
export const IMG_PATTERN = `https://user-images.githubusercontent.com/10141928/153707018-1d7f74e0-b6d5-4e06-8297-6aa72803b9db.png`;
export const IMG_PATTERN_LEFT = `https://user-images.githubusercontent.com/10141928/153782508-b5246337-c251-4698-b1dd-889e2a3014d2.png`;
export const IMG_PATTERN_SQUARE = `https://user-images.githubusercontent.com/10141928/155886147-1d56ce31-c90a-4748-8320-51fb6f90b52a.png`;
export const IMG_PATTERN_SQUARE_TOP = `https://user-images.githubusercontent.com/10141928/153779957-17dcfe06-1284-4072-b2dc-9657f964d7d3.png`;
/**
 * Invitato Image Assets
 * @important - DON'T CHANGE THIS CODE
 */
export const LOGO_INVITATO = `https://user-images.githubusercontent.com/10141928/150662036-816aabf8-76f9-4342-bc47-3d7892362753.png`;
export const IMG_COVID = {
  id: `https://user-images.githubusercontent.com/10141928/152914092-4dfb40e5-9552-49dc-a2d4-72a345f08b06.png`,
  en: `https://user-images.githubusercontent.com/10141928/147309670-887acb92-a3b6-4f9b-b78c-839c7d466b5c.jpg`,
};

//Collaboration
export const LOGO_VENDOR_DALANG = `https://ik.imagekit.io/invitatoid/global-assets/logo-partner/partner-dalang_bp5JgCazz.png?ik-sdk-version=javascript-1.4.3&updatedAt=1651639908257`;
export const LOGO_VENDOR_PENTONE_WHITE = `https://ik.imagekit.io/invitatoid/global-assets/logo-partner/partner-pentone-white_4cMUJafwM.png?ik-sdk-version=javascript-1.4.3&updatedAt=1654518552744`;
export const LOGO_VENDOR_PENTONE_BLACK =
  'https://user-images.githubusercontent.com/103095433/193169607-562d8316-835d-4c15-a6b4-2b3c7cd22621.png';
export const LOGO_VENDOR_TIRTOWENING =
  'https://ik.imagekit.io/invitatoid/global-assets/logo-partner/logo-vendor-tirtowening_2o6jmuIlN.png?ik-sdk-version=javascript-1.4.3&updatedAt=1655708911787';
export const LOGO_VENDOR_SAMAWA =
  'https://ik.imagekit.io/invitatoid/global-assets/logo-partner/logo-vendor-samawa_KniSfe28i.png?ik-sdk-version=javascript-1.4.3&updatedAt=1655708911529';
export const LOGO_VENDOR_SADEWA =
  'https://ik.imagekit.io/invitatoid/global-assets/logo-partner/logo-vendor-sadewa_6J3D8PmsJ.png?ik-sdk-version=javascript-1.4.3&updatedAt=1655708911185';
export const LOGO_VENDOR_PRAHASTA =
  'https://ik.imagekit.io/invitatoid/global-assets/logo-partner/logo-vendor-prahasta-white_5L4WQTBlc.png?ik-sdk-version=javascript-1.4.3&updatedAt=1655708910828';
export const LOGO_VENDOR_FLATTER =
  'https://ik.imagekit.io/invitatoid/global-assets/logo-partner/logo-vendor-flatter_SvHO8RoVF.png?ik-sdk-version=javascript-1.4.3&updatedAt=1655708910005';
export const LOGO_VENDOR_AKSARA =
  'https://ik.imagekit.io/invitatoid/global-assets/logo-partner/logo-vendor-aksara-white_Ofc6Xkib-.png?ik-sdk-version=javascript-1.4.3&updatedAt=1655708909659';
export const LOGO_VENDOR_DREAMWORKS =
  'https://ik.imagekit.io/invitatoid/global-assets/logo-partner/logo-vendor-dreamworks-white_FFlUUi8Dk.png?ik-sdk-version=javascript-1.4.3&updatedAt=1655708910073';
