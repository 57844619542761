import React from 'react';
import { Box, Heading, Text } from '@chakra-ui/react';

import WithAnimation from '@components/Common/WithAnimation';

import { THE_BRIDE } from '@/constants';
import { BG_DESKTOP_VIEW } from '@/constants/assets';

import useInvitation from '@hooks/useInvitation';

function LeftContent() {
  const isInvitation = useInvitation();

  return (
    <Box
      bgColor="bgPrimary"
      width="calc(100% - 500px)"
      height="100%"
      pos="fixed"
      bgImage={BG_DESKTOP_VIEW}
      bgSize="cover"
      padding="42px"
      borderRight="8px solid"
      borderRightColor="blackAlpha.400"
    >
      <WithAnimation>
        <Text letterSpacing="2px" fontSize="md" color="white">
          {isInvitation ? 'THE WEDDING OF' : 'WEDDING ANNOUNCEMENT'}
        </Text>
      </WithAnimation>
      <WithAnimation>
        <Heading
          margin="32px 0"
          fontWeight="normal"
          size="4xl"
          letterSpacing="2px"
          color="white"
          dangerouslySetInnerHTML={{ __html: THE_BRIDE }}
        />
      </WithAnimation>
      <WithAnimation>
        <Text fontStyle="italic" maxWidth="500px" color="white" letterSpacing="1px" textShadow="1px 0 white">
          “Dan di antara tanda-tanda kekuasaan-Nya ialah Dia menciptakan untukmu isteri-isteri dari jenismu sendiri, supaya kamu cenderung dan merasa tenteram kepadanya, dan dijadikan-Nya diantaramu rasa kasih dan sayang. Sesungguhnya pada yang demikian itu benar-benar terdapat tanda-tanda bagi kaum yang berfikir.”
          <br />- (QS Ar-Rum: 21)
        </Text>
      </WithAnimation>
    </Box>
  );
}

export default LeftContent;
